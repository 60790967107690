<template>
  <div>
    <div id="data-list-thumb-view" class="data-list-container">
      <vs-table ref="table"  max-items="1"    :data="users">

        <template slot="thead">
          <vs-th>Image</vs-th>
          <vs-th sort-key="name">{{$t('id')}}</vs-th>
          <vs-th sort-key="category">{{$t('name')}}</vs-th>
          <vs-th sort-key="popularity">{{$t('username')}}</vs-th>
          <vs-th sort-key="order_status">{{$t('balance')}}</vs-th>
          <vs-th sort-key="order_status">{{$t('Income')}}</vs-th>
          <vs-th sort-key="order_status">{{$t('Outcome')}}</vs-th>
          <vs-th sort-key="order_status">{{$t('Make Transaction')}}</vs-th>
        </template>

        <template slot-scope="{data}">
          <tbody>
          <vs-tr  :data="tr" :key="indextr" v-for="(tr, indextr) in users">

            <vs-td class="img-container">

              <vs-avatar size="70px" src="https://avatars2.githubusercontent.com/u/31676496?s=460&v=4"/>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.id }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.name }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.username }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.balance }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.balance }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.balance }}</p>
            </vs-td>
            <vs-td>
              <vs-button class="product-category">{{$t('Make Trans')}}</vs-button>
            </vs-td>
          </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
export default {
  /* eslint-disable */
  data:()=>({
    users:[
      {
        "id": 1,
        "name": "Leanne Graham",
        "username": "Bret",
        "email": "Sincere@april.biz",
        "website": "hildegard.org",
        "status": "Blocked",
        "phone":"53053043",
        "tc":"3434342434",
        "balance":"2323232",
        "blocked_reason": "because he is a gay",
        "rolecount":"1"
      }
    ],

    selected: [],
  }),
  methods:{
  },
  mounted(){

  },
  created() {

  }
}
</script>
<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;


      tr{
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
        td{
          padding: 10px;
          &:first-child{
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child{
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .product-img {
              height: 110px;
            }
          }
        }
        td.td-check{
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
